import { format, getDay, getHours, getMinutes, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { Event } from "../../interfaces/Event";
import { classNames } from "../../utils/CssUtil";
import {
  getFormattedHours,
  getTimeDifferenceInHours,
} from "../../utils/DateUtil";
import { ListVisitItem } from "../../interfaces/Visit";
import { getVisit } from "../../apis/visit";
import { getSettings } from "../../apis/setting";

type CalendarDay = {
  date: Date;
  events: Array<Event>;
};

type WeekViewProps = {
  weekDays: Array<Date>;
  events: Array<Event>;
  startDateOfWeek: number;
  onDayClick: (day: CalendarDay) => void;
  onViewDetails: (event: Event) => void;
  onViewVisit: (visit: ListVisitItem, event: Event) => void;
};

const WeekView: React.FC<WeekViewProps> = ({
  weekDays,
  events,
  onDayClick,
  onViewDetails,
  onViewVisit,
}) => {
  const [hourOffset, setHourOffSet] = useState(9);
  const [workingHours, setWorkingHours] = useState(12);
  const [weekViewHours, setWeekViewHours] = useState(
    getFormattedHours("09:30", "20:30")
  );
  const [weekOffDays, setWeeklyOffDays] = useState<Array<string>>([]);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = () => {
    getSettings().then((res: any) => {
      const [result] = res.data.data.filter(
        (i: any) => i.code === "is_open_24_hours"
      );
      if (result.value === "no") {
        const [openTime] = res.data.data.filter(
          (i: any) => i.code === "open_time"
        );
        const [closeTime] = res.data.data.filter(
          (i: any) => i.code === "close_time"
        );
        const [weeklyOffDays] = res.data.data.filter(
          (i: any) => i.code === "weekly_off_days"
        );
        const differenceInHours = getTimeDifferenceInHours(
          openTime.value,
          closeTime.value
        );
        setWeeklyOffDays(weeklyOffDays.value.split(","));
        setWorkingHours(differenceInHours);
        const formattedOpenTime = convertTo24Hour(openTime.value);
        const formattedCloseTime = convertTo24Hour(closeTime.value);
        getHoursOffset(openTime.value);
        setWeekViewHours(
          getFormattedHours(
            formattedOpenTime.toString(),
            formattedCloseTime.toString()
          )
        );
      }
    });
  };

  function getHoursOffset(timeString: any) {
    // Parse the time string into hours and minutes
    const timeParts = timeString.match(/(\d+):?(\d+)?\s?(AM|PM)/i);

    if (!timeParts) {
      setHourOffSet(parseInt(timeString));
      throw new Error("Invalid time format");
    }

    let hours = parseInt(timeParts[1], 10);
    const minutes = parseInt(timeParts[2] || "0", 10); // Default minutes to 0 if not present
    const period = timeParts[3].toUpperCase();

    // Convert to 24-hour format
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    // Calculate the hour offset
    const result = hours + minutes / 60;
    setHourOffSet(result);
  }

  const isWeeklyOff = (date: Date) => {
    // Get the day name from the date (e.g., Sunday, Monday)
    const dayName: string = format(date, "EEEE"); // Returns full day name like 'Sunday'
    // Check if the dayName exists in the weeklyOffDays array
    const result = weekOffDays.includes(dayName);
    return result;
  };

  const convertTo24Hour = (time12Hour: any) => {
    // Parse the 12-hour time string to a Date object
    const date = parse(time12Hour, "hh:mm a", new Date());

    // Format the Date object to 24-hour format
    return format(date, "HH:mm");
  };

  const handleViewEvent = (event: Event) => {
    if (event.isAppointment && event.id) {
      getVisit(event.id).then((res: any) => {
        onViewVisit(res, event);
      });
    } else {
      onViewDetails(event);
    }
  };

  const appointmentWindow = parseInt(
    window.localStorage.getItem("appointmentWindow") ?? "15"
  );

  return (
    <div
      style={{ width: "165%" }}
      className="flex flex-col flex-none max-w-full sm:max-w-none md:max-w-full"
    >
      <div className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
        <div className="grid-cols-7 -mr-px text-sm leading-6 text-gray-500 border-r border-gray-100 divide-x divide-gray-100 sm:grid">
          <div className="col-end-1 w-14"></div>
          {weekDays &&
            weekDays.map((day: any, i: number) => (
              <div
                key={i}
                className={`flex items-center justify-center py-3 ${
                  isWeeklyOff(day.date)
                    ? "bg-red-100 text-red-600"
                    : "bg-white text-gray-900"
                }`}
              >
                <span
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDayClick(day);
                  }}
                >
                  {format(day.date, "E")}

                  <span
                    className={`items-center justify-center font-semibold  ${
                      isWeeklyOff(day.date) ? "text-red-600" : "text-gray-900"
                    }`}
                  >
                    {" " + format(day.date, "dd")}
                  </span>
                </span>
              </div>
            ))}
        </div>
      </div>
      <div className="flex flex-auto">
        <div className="sticky left-0 flex-none bg-white w-14 ring-1 ring-gray-100"></div>
        <div className="grid flex-auto grid-cols-1 grid-rows-1 max-h-[148vh]">
          {/* <!-- Horizontal lines --> */}
          <div
            className="grid col-start-1 col-end-2 row-start-1 divide-y divide-gray-200"
            style={{
              gridTemplateRows: `repeat(${
                workingHours * 2
              }, minmax(3.5rem, 1fr))`,
            }}
          >
            {weekViewHours &&
              weekViewHours.length > 0 &&
              weekViewHours.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="relative sticky text-xs leading-5 text-left text-gray-400 -ml-14">
                    <span className="absolute px-1">{item}</span>
                  </div>
                  <div></div>
                </React.Fragment>
              ))}
          </div>

          {/* <!-- Vertical lines --> */}
          <div className="hidden grid-cols-7 col-start-1 col-end-2 grid-rows-1 row-start-1 divide-x divide-gray-200 sm:grid sm:grid-cols-7">
            <div className="col-start-1 row-span-full"></div>
            <div className="col-start-2 row-span-full"></div>
            <div className="col-start-3 row-span-full"></div>
            <div className="col-start-4 row-span-full"></div>
            <div className="col-start-5 row-span-full"></div>
            <div className="col-start-6 row-span-full"></div>
            <div className="col-start-7 row-span-full"></div>
            <div className="w-8 col-start-8 row-span-full"></div>
          </div>

          {/* Events */}
          <ol
            className="grid grid-cols-1 col-start-1 col-end-2 row-start-1 sm:grid-cols-7 sm:pr-8"
            style={{
              gridTemplateRows: `repeat(${24 * workingHours}, 1fr)`,
            }}
          >
            {events &&
              events.length > 0 &&
              events
                .sort(
                  (a: Event, b: Event) =>
                    new Date(b.startDate).getTime() -
                    new Date(a.startDate).getTime()
                )
                .map((event: Event, index: number) => (
                  <li
                    key={index}
                    className={classNames("relative flex")}
                    style={{
                      gridRow: `${
                        Math.max(
                          0,
                          (getHours(new Date(event.startDate)) - hourOffset) *
                            24 +
                            Math.floor(
                              (getMinutes(new Date(event.startDate)) * 2) / 5
                            )
                        ) + 1
                      } / span ${(appointmentWindow * 2) / 5}`,

                      gridColumnStart: getDay(new Date(event.startDate)) + 1,
                    }}
                  >
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleViewEvent(event);
                      }}
                      className={classNames(
                        event?.status && event.status === "completed"
                          ? "bg-green-400"
                          : "bg-indigo-400",
                        "inline-block w-full px-2 py-0.5 text-xs text-white text-ellipsis capitalize truncate  rounded-md border cursor-pointer group-hover:text-indigo-600"
                      )}
                    >
                      <div className="flex justify-between font-semibold">
                        {event.isAppointment ? (
                          <span className="text-xs">
                            {event.patientName}
                            <span className="px-2">
                              ({format(new Date(event.startDate), "hh:mm a")})
                            </span>
                          </span>
                        ) : (
                          <span className="text-xs">
                            {event.title}
                            <span className="px-2">
                              ({format(new Date(event.startDate), "hh:mm a")})
                            </span>
                          </span>
                        )}
                      </div>
                    </button>
                  </li>
                ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default WeekView;
