import { Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { getPatients } from "../../apis/patient";
import { getUsers } from "../../apis/user";
import {
  getClinicVisitTypes,
  getVisit,
  saveVisit,
  updateVisit,
} from "../../apis/visit";
import { Patient } from "../../interfaces/Patient";
import { User } from "../../interfaces/User";
import { useNotification } from "../../NotificationContext";
import { LanguageContext } from "../../providers/LanguageProvider";
import DatePicker from "react-datepicker";
import { getNextAppointmentTime } from "../../utils/DateUtil";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { checkDoctorAvailability, getEvents } from "../../apis/event";
import { getSettings } from "../../apis/setting";
import { Setting } from "../../interfaces/Setting";
import {
  endOfMonth,
  format,
  parse,
  setHours,
  setMinutes,
  startOfMonth,
} from "date-fns";

const AddAppointment: React.FC<{
  closeModal: () => void;
  patientId: any;
  userId?: number;
  existingVisitData?: any;
}> = ({ closeModal, patientId, userId, existingVisitData }) => {
  const appointmentWindow = parseInt(
    window.localStorage.getItem("appointmentWindow") ?? "15"
  );

  const [visit, setVisit] = useState({
    // date: getNextAppointmentTime(appointmentWindow),
    date: existingVisitData?.date || getNextAppointmentTime(appointmentWindow),
    isInpatient: true,
    patientId: "",
    parentId: null,
    clinicUserId: "",
    clinicVisitTypeId: 1,
    assessment: {},
    userId: existingVisitData?.userId || null,
    meta: {
      height: "",
      weight: "",
    },
    fee: 0,
    discount: 0,
    medicines: [],
    services: [],
    symptoms: [],
    provisionalDiagnosis: [],
    finalDiagnosis: [],
  });

  const { showNotification } = useNotification();
  const [patients, setPatients] = useState<Array<Patient>>([]);
  const [users, setUsers] = useState<Array<User>>([]);
  const Option = Select;
  const { translate: t } = useContext(LanguageContext);
  const [errors, setErrors] = useState<any>();
  const [clinicVisitTypes, setClinicVisitTypes] = useState([]);
  const [isPostponeVisit, setIsPostponeVisit] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState<any>();

  const [visitId, setVisitId] = useState<any>();
  const [weeklyOffDays, setWeeklyOffDays] = useState<Array<number>>([]);
  const [openTime, setOpenTime] = useState<Date | any>();
  const [closeTime, setCloseTime] = useState<Date | any>();
  const [isOpen24Hours, setIsOpen24Hours] = useState<boolean>(false);
  const [holidayDates, setHolidayDates] = useState<Array<Date>>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (patientId) {
      fetchPatients({ id: patientId });
      setVisit({ ...visit, patientId });
    } else {
      fetchPatients({});
    }
    fetchUsers({ role: "doctor" });
    fetchClinicVisitTypes();
    fetchSettings();
    fetchEvents();
  }, []);

  useEffect(() => {
    const userObj = window.localStorage.getItem("user");

    if (userObj) {
      const user = JSON.parse(userObj);
      setLoggedInUser(user);
      if (user.roles[0]?.name === "doctor") {
        setVisit((prevVisit) => ({ ...prevVisit, userId: user.id }));
      }
    }
  }, []);

  const fetchPatients = (params: any) => {
    getPatients(params).then((res: any) => {
      const sortedPatients = res.data.data.sort((a: any, b: any) => {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      });
      setPatients(sortedPatients);
    });
  };

  const fetchUsers = (params: any) => {
    getUsers(params).then((res: any) => {
      setUsers(res.data.data);
    });
  };

  const fetchClinicVisitTypes = async () => {
    await getClinicVisitTypes().then((res) => {
      setClinicVisitTypes(res.data);
      setVisit((prev) => ({
        ...prev,
        clinicVisitTypeId: res.data[0].id,
      }));
    });
  };

  const onSave = () => {
    if (existingVisitData && existingVisitData?.id) {
      rescheduleVisit();
    } else {
      createScheduleVisit();
    }
  };

  const createScheduleVisit = async () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);

    saveVisit({
      ...visit,
      date: new Date(visit.date).toISOString(),
      is_scheduled: true,
    })
      .then(() => {
        showNotification(
          "success",
          "Appointment Added successfully",
          "",
          "topRight"
        );
        closeModal();
      })
      .catch((err) => {
        setErrors(err.response.data.errors);
        const message = err?.response?.data?.message;
        if (message) {
          if (message.includes("|")) {
            setErrors({ date: message.split("|")[0] });
            const existingVisitId = message.split("|")[1];
            setVisitId(existingVisitId);
            setIsPostponeVisit(true);
          }
        } else if (err?.response?.data?.details?.message) {
          showNotification(
            "error",
            err?.response?.data?.details?.message,
            "",
            "topRight"
          );
        } else {
          showNotification(
            "error",
            "An error occurred while saving the visit",
            "",
            "topRight"
          );
        }
      });
  };

  const validateForm = () => {
    const validationErrors: any = {};
    const now = new Date();

    if (!visit?.userId) {
      validationErrors.userId = "* doctor name is required";
    }
    if (visit?.patientId === "") {
      validationErrors.patient = "* patient name is required";
    }

    if (!visit?.date || isNaN(new Date(visit.date).getTime())) {
      validationErrors.date = "* date is required";
    } else {
      const visitDateTime = new Date(visit.date);

      if (visitDateTime < now) {
        validationErrors.date = "* date must be after current time";
      }
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const handlePatientChange = (patientId: any) => {
    setVisit({
      ...visit,
      patientId,
    });
  };

  const handleUserChange = (userId: any) => {
    setVisit({
      ...visit,
      userId,
    });
  };

  const handleDateChange = (value: Date | null) => {
    if (value) {
      setVisit({
        ...visit,
        date: value,
      });
    }
  };

  const handleClinicVisitTypeChange = (item: any) => {
    setVisit({
      ...visit,
      clinicVisitTypeId: item,
    });
  };

  const handlePostponeVisit = async () => {
    let serialNumber;
    await getVisit(visitId).then((res: any) => {
      serialNumber = res.serialNumber;
    });

    try {
      const res: any = await updateVisit({
        ...visit,
        serialNumber: serialNumber,
        date: new Date(visit.date).toISOString(),
        id: visitId,
      });
      showNotification("success", "Visit updated successfully", "", "topRight");
      closeModal();
    } catch (err: any) {
      const message =
        err?.data?.message || "An error occurred while updating the visit";
      showNotification("error", message, "", "topRight");
    }
  };

  const handleClosePostponeVisitModal = () => {
    setIsPostponeVisit(false);
  };

  const createNewVisit = async () => {
    saveVisit({
      ...visit,
      date: new Date(visit.date).toISOString(),
      is_scheduled: false,
    })
      .then((res: any) => {
        showNotification(
          "success",
          "Appointment Added successfully",
          "",
          "topRight"
        );
        closeModal();

        navigate("/visits");
      })
      .catch((err) => {});
  };

  const checkAvailability = async (): Promise<boolean> => {
    try {
      const result = await checkDoctorAvailability({
        userId: visit.userId,
        date: new Date(visit.date).toISOString(),
      });
      return result?.data?.availability || false;
    } catch (error) {
      console.error("Error checking availability:", error);
      return false;
    }
  };

  const rescheduleVisit = async () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    if (!(await checkAvailability())) {
      setErrors({
        date: "Doctor is unavailable. Please select another date or time.",
      });
      return;
    }

    try {
      const res: any = await updateVisit({
        ...visit,
        date: new Date(visit.date).toISOString(),
        id: existingVisitData.id,
      });
      showNotification(
        "success",
        "Visit Reschedule successfully",
        "",
        "topRight"
      );
      closeModal();
    } catch (err: any) {
      const message =
        err?.data?.message || "An error occurred while updating the visit";
      showNotification("error", message, "", "topRight");
    }
  };

  const fetchSettings = () => {
    getSettings().then((res: any) => {
      const settings = res.data.data;
      const weeklyOffSetting = settings.find(
        (setting: Setting) => setting.code === "weekly_off_days"
      );

      if (weeklyOffSetting) {
        const offDays = weeklyOffSetting.value
          .split(",") // Assuming "Monday,Friday" format
          .map((day: string) => {
            const dayMapping: Record<string, number> = {
              Sunday: 0,
              Monday: 1,
              Tuesday: 2,
              Wednesday: 3,
              Thursday: 4,
              Friday: 5,
              Saturday: 6,
            };
            return dayMapping[day.trim()] ?? null; // Map to number or null if invalid
          })
          .filter((day: any) => day !== null); // Remove invalid days
        setWeeklyOffDays(offDays);
      }

      const openTimeSetting = settings.find(
        (setting: any) => setting.code === "open_time"
      );
      const closeTimeSetting = settings.find(
        (setting: any) => setting.code === "close_time"
      );

      const isOpen24HoursSetting = settings.find(
        (setting: any) => setting.code === "is_open_24_hours"
      );

      if (isOpen24HoursSetting.value === "yes") {
        setIsOpen24Hours(true);
      }

      if (openTimeSetting && closeTimeSetting) {
        const openTimeValue = convertTo24Hour(openTimeSetting.value);
        const closeTimeValue = convertTo24Hour(closeTimeSetting.value);

        setOpenTime(openTimeValue);
        setCloseTime(closeTimeValue);
      }
    });
  };

  const convertTo24Hour = (time12Hour: any) => {
    const date = parse(time12Hour, "hh:mm a", new Date());
    return format(date, "HH:mm");
  };

  const isDayDisabled = (date: Date) => {
    return !weeklyOffDays.includes(date.getDay());
  };

  const isTimePickerActive = (time: Date) => {
    if (isOpen24Hours) {
      return true;
    } else {
      const [openHours, openMinutes] = openTime.split(":").map(Number);
      const [closeHours, closeMinutes] = closeTime.split(":").map(Number);
      const openDateTime = setMinutes(
        setHours(new Date(time), openHours),
        openMinutes
      );
      const closeDateTime = setMinutes(
        setHours(new Date(time), closeHours),
        closeMinutes
      );
      return !(new Date(time) < openDateTime || new Date(time) > closeDateTime);
    }
  };

  const fetchEvents = () => {
    const startDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
    const endDate = format(endOfMonth(new Date()), "yyyy-MM-dd");
    getEvents({
      start: startDate,
      end: endDate,
      per_page: 1000,
    }).then((res: any) => {
      const events = res?.data;
      const holidayEvents = events?.filter(
        (event: any) => event?.eventCategory?.name === "holiday"
      );

      if (holidayEvents && holidayEvents.length > 0) {
        const holidays = holidayEvents.map(
          (event: any) => new Date(event.startDate)
        );
        setHolidayDates(holidays);
      }
    });
  };

  return (
    <div className="">
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("patient_name")}
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={handlePatientChange}
            value={visit.patientId}
            disabled={patientId ? true : false}
          >
            {patients.map((patient) => (
              <Option key={patient.id} value={patient.id}>
                {patient.firstName + " " + patient.lastName}
              </Option>
            ))}
          </Select>
          {errors && errors.patient && (
            <span className="text-sm text-red-500 leading-0">
              {errors.patient}
            </span>
          )}
        </div>
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {"Doctor Name"}
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={handleUserChange}
            value={visit.userId}
            disabled={
              loggedInUser?.roles[0]?.name === "doctor" ||
              existingVisitData?.userId
                ? true
                : false
            }
          >
            {users.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.firstName + " " + user.lastName}
              </Option>
            ))}
          </Select>
          {errors && errors.userId && (
            <span className="text-sm text-red-500 leading-0">
              {errors.userId}
            </span>
          )}
        </div>
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {"Visit Type"}
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={handleClinicVisitTypeChange}
            value={visit.clinicVisitTypeId}
          >
            {clinicVisitTypes.map((item: any) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
          {errors && errors.clinicUserId && (
            <span className="text-sm text-red-500 leading-0">
              {errors.clinicUserId}
            </span>
          )}
        </div>
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("date")}
        </label>
        <div className="mt-1">
          <DatePicker
            selected={new Date(visit.date)}
            onChange={handleDateChange}
            portalId="root-panel"
            showTimeSelect={true}
            timeFormat="h:mm aa"
            timeIntervals={appointmentWindow}
            dateFormat="MMMM d, yyyy h:mm aa"
            filterDate={isDayDisabled}
            filterTime={isTimePickerActive}
            excludeDates={holidayDates}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />

          {errors && errors.date && (
            <span className="text-sm text-red-500 leading-0">
              {errors.date}
            </span>
          )}
        </div>
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => onSave()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {t("save")}
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>

      {isPostponeVisit && (
        <ConfirmationModal
          message="You have already scheduled this visit with the same doctor on same date. Do you want to postpone this visit?"
          onClose={() => handleClosePostponeVisitModal()}
          onSubmit={() => {
            handlePostponeVisit();
            setIsPostponeVisit(false);
          }}
          okLabel="Yes"
          cancelLabel="Cancel"
          noLabel="No"
          onNo={() => {
            setIsPostponeVisit(false);
            createNewVisit();
          }}
        ></ConfirmationModal>
      )}
    </div>
  );
};

export default AddAppointment;
