import { format, addMinutes, parse, differenceInMinutes } from "date-fns";
const getFormattedHours = (startHour: any, endHour: any) => {
  const start = parse(startHour, "HH:mm", new Date());
  const end = parse(endHour, "HH:mm", new Date());

  let current = start;
  const result = [];

  while (current <= end) {
    result.push(format(current, "hh:mm a")); // Convert to 12-hour format
    current = addMinutes(current, 60); // Increment by 1 hour
  }
  return result;
};

const getNextAppointmentTime = (window: number) => {
  const now = new Date();

  // Get the current minutes
  let minutes = now.getMinutes();

  // Calculate how much time to add to round up to the next interval
  const remainder = minutes % window;
  const addMinutes = remainder === 0 ? 0 : window - remainder;

  // Set the future time with the calculated minutes
  now.setMinutes(minutes + addMinutes);

  // Return the rounded-up time as a string (optional: format it as you need)
  return now;
};

const getTimeDifferenceInHours = (time1: any, time2: any) => {
  // Parse the time strings into Date objects
  const date1 = parse(time1, "hh:mm a", new Date());
  const date2 = parse(time2, "hh:mm a", new Date());

  // Calculate the difference in minutes
  const minutesDifference = differenceInMinutes(date2, date1);

  // Convert minutes to hours
  return minutesDifference / 60;
};

export { getFormattedHours, getNextAppointmentTime, getTimeDifferenceInHours };
