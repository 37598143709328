import { BASE_URL } from "../constants";
import { Setting } from "../interfaces/Setting";
import { Upgrade } from "../interfaces/Transaction";
import api from "../middlware/api";
import { camelToSnake, snakeCase } from "../utils/CamelToSnake";

export const getSettings = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/configurations`)
      .then((res) => {
        const {
          data: { data: config },
        } = res;
        const appointmentWindow = config.find(
          (item: Setting) => item.code === "appointment_window"
        ).value;
        window.localStorage.setItem("appointmentWindow", appointmentWindow);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateSettings = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .put(
        `${BASE_URL}/api/v1/configurations/${payload.id}`,
        camelToSnake(payload)
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveSettings = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/configurations`, camelToSnake(payload))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateValue = (payload: any) => {
  const formData = new FormData();
  let key: any;
  for (key in payload) {
    formData.append(snakeCase(key), payload[key] as Blob);
  }
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/configurations/${payload.id}/value`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSetting = (id: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/configurations/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSettingByCode = (code: string) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/configurations/by/${code}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDefaultDoctor = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .patch(`${BASE_URL}/api/v1/configurations/default-doctor`, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const upgradePlan = (payload: Upgrade) => {
  return api.post(
    `${BASE_URL}/api/v1/plan/upgrade`,
    camelToSnake({ ...payload, plan: payload.name })
  );
};

export const buyAddon = (payload: { name: string; quantity: number }) => {
  return api.post(`${BASE_URL}/api/v1/buy/addon`, payload);
};

export const updateClinicDetails = (payload: any) => {
  return new Promise((resolve, reject) => {
    api.put(`${BASE_URL}/api/v1/clinics/update`, payload).then((res) => {
      const userObj = window.localStorage.getItem('user');
      if (userObj) {
        const user = JSON.parse(userObj);
        const updatedUser = { ...user, activeClinic: res.data.clinic }
        window.localStorage.setItem("user", JSON.stringify(updatedUser));
      }
      resolve(res)
    }).catch((err) => {
      reject(err)
    });

  });
}
