import React, { useEffect, useState } from "react";
import { Event, EventCategory } from "../../interfaces/Event";
import { format, parse, setHours, setMinutes } from "date-fns";
import { Select } from "antd";
import {
  addEvent,
  checkDoctorAvailability,
  getEventCategories,
} from "../../apis/event";
import { useNotification } from "../../NotificationContext";
import { getUsers } from "../../apis/user";
import { User } from "../../interfaces/User";
import ConfirmationModal from "../../components/ConfirmationModal";
import { getPatients } from "../../apis/patient";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AxiosError } from "axios";
import { getClinicVisitTypes } from "../../apis/visit";
import { getSetting, getSettings } from "../../apis/setting";
import { Setting } from "../../interfaces/Setting";
const AddEvent: React.FC<{
  closeModal: () => void;
  startDate: Date | null;
  holidayEvents?: any;
}> = ({ closeModal, startDate, holidayEvents }) => {
  const { showNotification } = useNotification();
  const [clinicVisitTypes, setClinicVisitTypes] = useState<any>([]);

  const [event, setEvent] = useState<Event>({
    id: null,
    title: "",
    eventCategoryId: 3,
    userId: null,
    user: "",
    description: "test",
    isRecurring: false,
    startDate: startDate ?? new Date(),
    endDate: new Date(),
    patientId: null,
    recurringType: null,
    isAppointment: true,
    isAllDay: false,
    recurringUntil: null,
    clinicVisitTypeId: "",
    status: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [eventCategories, setEventCategories] = useState<Array<any>>([]);
  const [users, setUsers] = useState<Array<User>>([]);
  const [patients, setPatients] = useState<Array<User>>([]);
  const [isConfimationBoxVisibile, setConfirmationBoxVisibility] =
    useState<boolean>(false);
  const Option = Select;

  const [filteredPatients, setFilteredPatients] = useState(patients);
  const [loggedInUser, setLoggedInUser] = useState<any>();
  const [weeklyOffDays, setWeeklyOffDays] = useState<Array<number>>([]);
  const [openTime, setOpenTime] = useState<Date | any>();
  const [closeTime, setCloseTime] = useState<Date | any>();
  const [isOpen24Hours, setIsOpen24Hours] = useState<boolean>(false);
  const [holidayDates, setHolidayDates] = useState<Array<Date>>([]);

  useEffect(() => {
    // Extract holiday dates from the holiday events and convert them to Date objects
    if (holidayEvents && holidayEvents.length > 0) {
      const holidays = holidayEvents.map(
        (event: any) => new Date(event.startDate)
      );
      setHolidayDates(holidays);
    }
  }, [holidayEvents]);

  useEffect(() => {
    const userObj = window.localStorage.getItem("user");
    if (userObj && !event.userId) {
      const user = JSON.parse(userObj);
      setLoggedInUser(user);
      if (user.roles[0]?.name === "doctor") {
        setEvent((prevEvent) => ({ ...prevEvent, userId: user.id }));
      }
    }
  }, [users, event.userId]);

  useEffect(() => {
    fetchEventCategories();
    fetchUsers();
    fetchPatients({});
    if (startDate) {
      setEvent({ ...event, startDate });
    }
    fetchClinicVisitTypes();
    fetchSettings();
  }, []);

  const fetchEventCategories = () => {
    getEventCategories().then((res: any) => {
      const appointmentCategory = res.data.find(
        (category: EventCategory) => category.name === "appointment"
      );
      setEvent({
        ...event,
        eventCategoryId: appointmentCategory.id,
        isAppointment: true,
      });
      setEventCategories(res.data);
    });
  };

  const fetchUsers = () => {
    getUsers({ per_page: 1000 }).then((res: any) => {
      setUsers(res.data.data);
    });
  };

  const fetchPatients = (params: any) => {
    getPatients(params).then((res: any) => {
      setPatients(res.data.data);
    });
  };

  const onSearch = (value: string) => {
    const filtered = patients.filter((patient) =>
      `${patient.firstName} ${patient.lastName}`
        .toLowerCase()
        .includes(value.toLowerCase())
    );
    setFilteredPatients(filtered);
  };

  const handleDateChange = (
    dateType: "startDate" | "endDate" | "recurringUntil",
    date: Date | null
  ) => {
    setEvent((prev) => ({
      ...prev,
      [dateType]: date || new Date(),
    }));
  };

  const fetchClinicVisitTypes = async () => {
    await getClinicVisitTypes().then((res) => {
      setClinicVisitTypes(res.data);
      setEvent((prev) => ({
        ...prev,
        clinicVisitTypeId: res.data[0].id,
      }));
    });
  };

  const validateEventForm = () => {
    const validationErrors: Record<string, string> = {};
    if (!event.isAppointment && event?.title === "") {
      validationErrors.title = "* Title is required";
    }

    if (event?.userId === null) {
      validationErrors.userId = "* Please select Assignee";
    }

    if (event.isAppointment && event?.patientId === null) {
      validationErrors.patientId = "* Please select Patient";
    }

    const startDate = event.startDate ? new Date(event.startDate) : null;
    const endDate = event.endDate ? new Date(event.endDate) : null;

    if (startDate && endDate) {
      if (!event.isAppointment) {
        if (endDate < startDate) {
          // If endDate is earlier than startDate, trigger error
          validationErrors.endDate = "* End date must be after the start date";
        } else if (
          startDate.toDateString() === endDate.toDateString() &&
          endDate.getTime() < startDate.getTime()
        ) {
          // If dates are the same, check if the time is valid
          validationErrors.endDate = "* End time must be after the start time";
        }
      }
    }

    return validationErrors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateEventForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    const result = await checkDoctorAvailability({
      userId: event.userId!,
      date: event.startDate.toISOString(),
    });
    if (!result.data.availability) {
      setConfirmationBoxVisibility(true);
    } else {
      await createEvent();
    }
  };

  const createEvent = async () => {
    try {
      await addEvent({
        ...event,
        startDate: event.startDate.toISOString(),
        endDate: event.endDate?.toISOString() || null,
        recurringUntil: event.recurringUntil
          ? format(event.recurringUntil, "yyyy-MM-dd")
          : null,
        isRecurring: Boolean(event.recurringType),
        is_scheduled: true,
      });
      showNotification("success", "Event Added Successfully", "", "topRight");
      closeModal();
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        showNotification(
          "error",
          err?.response?.data.details.message,
          "",
          "topRight"
        );
      }
    }
  };

  const handleConfirmationSubmit = () => {
    createEvent();
    setConfirmationBoxVisibility(false);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationBoxVisibility(false);
  };

  const handleEventCategory = (eventCategoryId: string) => {
    const categoryId = parseInt(eventCategoryId);
    const selectedCategory: EventCategory = eventCategories.find(
      (category: EventCategory) => category.id === categoryId
    );
    const isAppointment = selectedCategory.name === "appointment";
    setEvent({ ...event, eventCategoryId: categoryId, isAppointment });
  };

  const handleClinicVisitTypeChange = (item: any) => {
    setEvent({
      ...event,
      clinicVisitTypeId: item,
    });
  };

  const fetchSettings = () => {
    getSettings().then((res: any) => {
      const settings = res.data.data;

      const weeklyOffSetting = settings.find(
        (setting: Setting) => setting.code === "weekly_off_days"
      );

      if (weeklyOffSetting) {
        const offDays = weeklyOffSetting.value
          .split(",") // Assuming "Monday,Friday" format
          .map((day: string) => {
            const dayMapping: Record<string, number> = {
              Sunday: 0,
              Monday: 1,
              Tuesday: 2,
              Wednesday: 3,
              Thursday: 4,
              Friday: 5,
              Saturday: 6,
            };
            return dayMapping[day.trim()] ?? null; // Map to number or null if invalid
          })
          .filter((day: any) => day !== null); // Remove invalid days
        setWeeklyOffDays(offDays);
      }

      const openTimeSetting = settings.find(
        (setting: any) => setting.code === "open_time"
      );
      const closeTimeSetting = settings.find(
        (setting: any) => setting.code === "close_time"
      );

      const isOpen24HoursSetting = settings.find(
        (setting: any) => setting.code === "is_open_24_hours"
      );

      if (isOpen24HoursSetting.value === "yes") {
        setIsOpen24Hours(true);
      }

      if (openTimeSetting && closeTimeSetting) {
        const openTimeValue = convertTo24Hour(openTimeSetting.value);
        const closeTimeValue = convertTo24Hour(closeTimeSetting.value);
        setOpenTime(openTimeValue);
        setCloseTime(closeTimeValue);
      }
    });
  };

  const convertTo24Hour = (time12Hour: any) => {
    // Parse the 12-hour time string to a Date object
    const date = parse(time12Hour, "hh:mm a", new Date());
    // Format the Date object to 24-hour format
    return format(date, "HH:mm");
  };

  const isDayDisabled = (date: Date) => {
    return !weeklyOffDays.includes(date.getDay());
  };

  const isTimePickerActive = (time: Date) => {
    if (isOpen24Hours || !event.isAppointment) {
      return true;
    } else {
      const [openHours, openMinutes] = openTime.split(":").map(Number);
      const [closeHours, closeMinutes] = closeTime.split(":").map(Number);
      const openDateTime = setMinutes(
        setHours(new Date(time), openHours),
        openMinutes
      );
      const closeDateTime = setMinutes(
        setHours(new Date(time), closeHours),
        closeMinutes
      );
      return !(new Date(time) < openDateTime || new Date(time) > closeDateTime);
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Event Category
        </label>
        <div className="mt-1">
          <select
            name="event-category"
            id="event-category"
            className="w-full max-w-xl pt-1 pb-1 capitalize border-gray-300 rounded-lg"
            value={event.eventCategoryId || ""}
            onChange={(e) => handleEventCategory(e.target.value)}
          >
            {eventCategories &&
              eventCategories.length > 0 &&
              eventCategories.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      {!event.isAppointment && (
        <div className="pt-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Title
          </label>
          <div className="mt-1">
            <input
              id="title"
              type="text"
              value={event?.title}
              onChange={(e) => setEvent({ ...event, title: e.target.value })}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>

          {errors && errors.title && (
            <span className="text-sm text-red-500 leading-0">
              {errors.title}
            </span>
          )}
        </div>
      )}

      {!event.isAppointment && (
        <div className="flex justify-start pt-4 gap-x-6 ">
          <div className="relative flex items-start">
            <div className="flex items-center h-6">
              <input
                aria-describedby="is-all-day-event"
                id="all-day"
                type="checkbox"
                onChange={() =>
                  setEvent({ ...event, isAllDay: !event.isAllDay })
                }
                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="all-day" className="font-medium text-gray-900">
                Is All Day Event?
              </label>
            </div>
          </div>
          <div className="flex w-1/2">
            <Select
              style={{
                width: "100%",
              }}
              value={event.recurringType}
              onChange={(value) => setEvent({ ...event, recurringType: value })}
            >
              <Option value={null}>Does Not Repeat</Option>
              <Option value="daily">Daily</Option>
              <Option value="weekly">
                Weekly on {format(event.startDate, "EEEE")}
              </Option>
            </Select>
          </div>
        </div>
      )}

      <EventDatePicker
        label={event.isAppointment ? "Date" : "Start Date"}
        selectedDate={event.startDate}
        handleChange={(date) => handleDateChange("startDate", date)}
        showTimeSelect={!event.isAllDay}
        errors={errors.startDate}
        filterDate={isDayDisabled}
        filterTime={isTimePickerActive}
        excludeDates={holidayDates}
      />
      {!event.isAppointment && (
        <EventDatePicker
          label="End Date"
          selectedDate={event.endDate}
          handleChange={(date) => handleDateChange("endDate", date)}
          showTimeSelect={!event.isAllDay}
          errors={errors.endDate}
        />
      )}
      {!event.isAppointment && event.recurringType && (
        <EventDatePicker
          label="Repeat Until"
          selectedDate={event.recurringUntil}
          handleChange={(date) => handleDateChange("recurringUntil", date)}
          showTimeSelect={false}
        />
      )}

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Assignee
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%", inlineSize: "100%" }}
            onChange={(value) => setEvent({ ...event, userId: value })}
            value={event.userId}
            disabled={loggedInUser?.roles[0]?.name === "doctor"}
          >
            {users?.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.firstName + " " + user.lastName}
              </Option>
            ))}
          </Select>
          {errors && errors.userId && (
            <span className="text-sm text-red-500 leading-0">
              {errors.userId}
            </span>
          )}
        </div>
      </div>

      {event.isAppointment && (
        <div className="pt-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Select Visit Type
          </label>
          <div className="mt-1">
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={handleClinicVisitTypeChange}
              onSearch={onSearch}
              value={event.clinicVisitTypeId}
            >
              {clinicVisitTypes.map((item: any) => (
                <Option key={item?.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}
      {event.isAppointment && (
        <div className="pt-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Select Patient
          </label>
          <div className="mt-1">
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={(value) => setEvent({ ...event, patientId: value })}
              onSearch={onSearch}
              value={event.patientId}
            >
              {patients.map((patient) => (
                <Option key={patient.id} value={patient.id}>
                  {patient.firstName + " " + patient.lastName}
                </Option>
              ))}
            </Select>
            {errors && errors.patientId && (
              <span className="text-sm text-red-500 leading-0">
                {errors.patientId}
              </span>
            )}
          </div>
        </div>
      )}

      <div className="flex items-center justify-end py-4 gap-x-4 border-gray-900/10 ">
        <button
          onClick={() => closeModal()}
          type="button"
          className="px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 border rounded-md"
        >
          Cancel
        </button>
        <button
          onClick={() => handleSubmit()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          Save
        </button>
      </div>
      {isConfimationBoxVisibile && (
        <ConfirmationModal
          message="You have conflicting events. Are you sure do you want to still add this?"
          onClose={handleCloseConfirmationModal}
          onSubmit={handleConfirmationSubmit}
          okLabel="Yes"
          cancelLabel="Cancel"
        ></ConfirmationModal>
      )}
    </div>
  );
};

const EventDatePicker: React.FC<{
  label: string;
  selectedDate: Date | null;
  handleChange: (date: Date | null) => void;
  showTimeSelect: boolean;
  errors?: string;
  excludeDates?: any;
  filterDate?: any;
  filterTime?: any;
}> = ({
  label,
  selectedDate,
  handleChange,
  showTimeSelect,
  errors,
  filterDate,
  filterTime,
  excludeDates,
}) => {
  const appointmentWindow = parseInt(
    window.localStorage.getItem("appointmentWindow") ?? "15"
  );
  return (
    <div className="pt-2">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-1">
        <DatePicker
          selected={selectedDate}
          onChange={handleChange}
          portalId="root-panel"
          showTimeSelect={showTimeSelect}
          timeFormat="h:mm aa"
          timeIntervals={appointmentWindow}
          dateFormat={
            !showTimeSelect ? "MMMM d, yyyy" : "MMMM d, yyyy hh:mm aa"
          }
          filterDate={filterDate}
          filterTime={filterTime}
          excludeDates={excludeDates}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      {errors && <span className="text-sm text-red-500">{errors}</span>}
    </div>
  );
};

export default AddEvent;
