import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../providers/LanguageProvider";
import { VisitType } from "../visits/VisitTypes";
import { getClinicVisitTypes } from "../../apis/visit";
import Modal from "../../components/Modal";
import AddVisitType from "./AddVisitType";
import { Button, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";

const VisitTypeList = () => {
  const navigate = useNavigate();
  const [replicatedVisitId, setReplicateVisitId] = useState<number | null>(
    null
  );
  const [editableId, setEditableId] = useState(null);
  const [isVisitTypeModalopen, setVisitTypeModalVisibility] =
    useState<boolean>(false);
  const { translate: t } = useContext(LanguageContext);

  const [visitTypes, setVisitTypes] = useState<Array<VisitType>>([]);
  useEffect(() => {
    fetchVisitTypes();
  }, []);

  const fetchVisitTypes = () => {
    getClinicVisitTypes().then((res: any) => {
      setVisitTypes(res.data);
    });
  };

  const editVisitType = (clinicVisitTypeId: any) => {
    if (clinicVisitTypeId) {
      setEditableId(clinicVisitTypeId);
      setVisitTypeModalVisibility(true);
    }
  };

  const handleCloseModal = () => {
    setEditableId(null);
    setVisitTypeModalVisibility(false);
    fetchVisitTypes();
  };

  const handleReplicate = (item: any) => {
    setReplicateVisitId(item.id);
    setVisitTypeModalVisibility(true);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex lg:hidden">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("specialities")}
          </h1>
        </div>
        <div className="flex flex-row-reverse w-full">
          <div>
            <button
              type="button"
              onClick={() => setVisitTypeModalVisibility(true)}
              className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hover"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="hidden sm:items-center lg:flex">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("specialities")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("visit_type_description")}
          </p>
        </div>
        <div className="flex mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => setVisitTypeModalVisibility(true)}
            className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("add")} {t("visit_type")}
          </button>
        </div>
      </div>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("name")}
                    </th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("actions")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {visitTypes && visitTypes.length > 0 ? (
                    visitTypes?.map((item: any) => (
                      <tr key={item.id}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 capitalize whitespace-nowrap sm:pl-6">
                          {item.name}
                          {item.isDefault && (
                            <span className="inline-flex items-center px-2 py-1 ml-2 text-xs font-medium text-green-700 rounded-full bg-green-50 ring-1 ring-inset ring-green-600/20">
                              Default
                            </span>
                          )}
                        </td>

                        <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-left whitespace-nowrap sm:pr-6">
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: "Edit",
                                  label: (
                                    <span
                                      onClick={() =>
                                        editVisitType(item.visitType.id)
                                      }
                                      rel="noopener noreferrer"
                                    >
                                      {t("edit")}
                                    </span>
                                  ),
                                  disabled: item.isDefault === true,
                                },
                                {
                                  key: "Replicate",
                                  label: (
                                    <span onClick={() => handleReplicate(item)}>
                                      {t("replicate layout")}
                                    </span>
                                  ),
                                },
                                {
                                  key: "View Layout",
                                  label: (
                                    <span
                                      onClick={() =>
                                        navigate("/layout-design", {
                                          state: {
                                            clinicVisitTypeId: item.id,
                                          },
                                        })
                                      }
                                    >
                                      {t("view layout")}
                                    </span>
                                  ),
                                },
                              ],
                            }}
                            placement="bottomLeft"
                          >
                            <Button>{t("actions")}</Button>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={2}
                        className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        {t("Record Not Found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isVisitTypeModalopen && (
        <Modal
          modalContent={
            <AddVisitType
              editableId={editableId}
              replicatedVisitId={replicatedVisitId}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
    </div>
  );
};
export default VisitTypeList;
